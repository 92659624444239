import { SourceFlow } from '@brenger/api-client';
import { SessionStorageKeys } from '../typings';
import { pushToDataLayer } from './basics';

const UUID_SESSION_KEY = 'uuid';

export interface GaClassicEvent {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
}

export interface ProductSelectionEvent {
  event: 'productSelection';
  action: 'impression' | 'click' | 'manual' | 'manual-zero-results' | 'popular' | 'zero-results';
  query: string;
  id: string;
  listLength: number;
  listPosition: number;
  attempts?: number;
}

export const trackEvent = (eventData: GaClassicEvent | ProductSelectionEvent): void => {
  if ('eventCategory' in eventData && 'eventAction' in eventData) {
    pushToDataLayer({
      event: 'trackEvent',
      ...eventData,
    });
    return;
  }
  pushToDataLayer(eventData);
};

export const trackEventPurchase = (trId: string | null, flowType: SourceFlow, amount: string): void => {
  const isFired = sessionStorage.getItem(SessionStorageKeys.IS_TRANSACTION_EVENT_FIRED);
  // Prevent double tracking for this session
  if (!trId || isFired) {
    return;
  }
  // Push purchase event
  const name = flowType === 'Business' ? 'purchase-business' : 'purchase';
  pushToDataLayer({
    event: name,
    transactionId: trId,
    transactionAffiliation: flowType.toLowerCase(),
    transactionTotal: amount,
    transactionProducts: [
      {
        sku: flowType.toLowerCase(),
        name: 'Transport request',
        price: amount,
        quantity: 1,
      },
    ],
  });
  // Flag as handled, this prevents extra events during the same session and will be reset when needed
  sessionStorage.setItem(SessionStorageKeys.IS_TRANSACTION_EVENT_FIRED, '1');
};

// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
/* tslint:disable */
export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
/* tslint:enable */

// Retrieves a client-generated uuid from session storage and if it does not
// exist, creates one for the current session.
const retrieveSessionId = (): string | null => {
  return window.sessionStorage.getItem(UUID_SESSION_KEY);
};

// Returns a list of the GA clientIds. Wrapped in a try/catch for graceful error handling in
// cases where getAll may not be a function.
const retrieveClientIdFromGa = (): string | undefined => {
  try {
    const tracker = window?.ga?.getAll();
    if (!tracker[0]) {
      return;
    }
    return tracker[0].get('clientId');
  } catch (err) {
    return undefined;
  }
};

const createSessionId = (): string => {
  const sessionId = uuidv4();
  window.sessionStorage.setItem(UUID_SESSION_KEY, sessionId);
  return sessionId;
};

// Finds and returns all session ids for this client.
export const getSessionIds = (): string[] => {
  const sessionIds: string[] = [];
  // Add any GA session IDs on the client
  const gaClientId = retrieveClientIdFromGa();
  if (gaClientId) {
    sessionIds.push(gaClientId);
  }
  // Add any existing session IDs (the ones we created)
  const existingSessionId = retrieveSessionId();
  if (existingSessionId) {
    sessionIds.push(existingSessionId);
  }
  // If cannot find any session IDs laying around for this client, create one.
  if (!sessionIds.length) {
    const newSessionId = createSessionId();
    sessionIds.push(newSessionId);
  }
  return sessionIds;
};

export const appendAbPixel = (eid: number, referrer: string): void => {
  const id = `tr_img_${eid}`;
  // Bail early
  if (document.getElementById(id)) {
    return;
  }
  try {
    const ref = new URL(referrer);
    const languageDir = ref.pathname.split('/')[1];
    const img = document.createElement('img') as HTMLImageElement;
    img.src = `${ref.origin}/${languageDir}/wp-content/plugins/bt-bb-ab/pixel.php?eid=${eid}`;
    img.id = id;
    img.width = 1;
    img.height = 1;
    img.alt = '';
    document.body.appendChild(img);
  } catch (_) {
    return;
  }
};
