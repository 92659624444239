"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportRequestState = void 0;
var TransportRequestState;
(function (TransportRequestState) {
    TransportRequestState["DRAFT"] = "draft";
    TransportRequestState["SUBMITTED"] = "submitted";
    TransportRequestState["STAGED"] = "staged";
    TransportRequestState["EXPIRED_DRAFT"] = "expired_draft";
    TransportRequestState["PUBLISHED"] = "published";
    TransportRequestState["PICKUP_CONFIRMED_BY_CUSTOMER"] = "pickup_confirmed_by_customer";
    TransportRequestState["PICKUP_CONFIRMED_BY_DRIVER"] = "pickup_confirmed_by_driver";
    TransportRequestState["PICKED_UP"] = "picked_up";
    TransportRequestState["CANCELLED"] = "cancelled";
})(TransportRequestState || (exports.TransportRequestState = TransportRequestState = {}));
